import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";

import { useLibraryFormStore } from '@/stores';
import { ref, computed, onMounted } from 'vue';
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0,
  class: "text-stone-800"
};
const _hoisted_3 = {
  key: 1,
  class: "text-stone-800"
};
export default {
  __name: 'CreateLibraryConnect',
  setup(__props) {
    const form = useLibraryFormStore();
    const currentItem = ref(0);
    const delayed = ref(false);
    const loadingComplete = computed(() => form.libPreview && delayed.value);
    const loadingSuccess = computed(() => libraryPreviewStatus.value && !libraryPreviewStatus.value.error);
    const libraryPreviewStatus = computed(() => {
      if (loadingComplete.value && form.librarySizeEstimate < 10) {
        return {
          text: "Google analytics account has < 10 pages.",
          error: "WARN"
        };
      } else {
        return {
          text: "Analyzing library status"
        };
      }
    });
    const items = computed(() => {
      return [{
        text: "Connecting library data sources"
      }, {
        text: "Previewing library pages and recommendations"
      }, libraryPreviewStatus.value];
    });
    onMounted(() => {
      setTimeout(function () {
        currentItem.value = 1;
      }, 1700);
      setTimeout(function () {
        currentItem.value = 2;
      }, 2900);
      setTimeout(function () {
        delayed.value = true;
      }, 2000);
    });
    return (_ctx, _cache) => {
      const _component_CreateLibraryHeader = _resolveComponent("CreateLibraryHeader");
      const _component_LoadingList = _resolveComponent("LoadingList");
      const _component_CreateLibraryFooter = _resolveComponent("CreateLibraryFooter");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_CreateLibraryHeader, {
        "current-num": "4",
        "total-num": "6"
      }, {
        title: _withCtx(() => [_createTextVNode(" Connecting data sources ")]),
        description: _withCtx(() => [_createTextVNode(" We're quickly analyzing the health of your connected data. ")]),
        _: 1
      }), _createVNode(_component_LoadingList, {
        class: "shadow-inner mb-8 mt-4",
        items: _unref(items),
        "current-item": _unref(currentItem),
        complete: _unref(loadingComplete)
      }, null, 8, ["items", "current-item", "complete"]), _unref(loadingComplete) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_unref(loadingSuccess) ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Your data sources look good – just one more step to finish creating your library. ")) : (_openBlock(), _createElementBlock("p", _hoisted_3, " We found a data issue you may want to review before continuing. "))])) : _createCommentVNode("", true), _createVNode(_component_CreateLibraryFooter, {
        "next-to": "CreateLibraryFilters",
        "previous-to": "CreateLibrarySearch",
        "next-enabled": _unref(loadingComplete)
      }, null, 8, ["next-enabled"])], 64);
    };
  }
};