
export function estimateKeywordVolume(position, impressions) {
	if (position === undefined || impressions === undefined) return undefined

	if (impressions > 10) {
		// Multiply impressions by an exponential function that gets smaller as you approach 1st position
		// Math.max(position - 3, 1) offsets so there is no volume growth until after the 4th position
		// Math.min(..., 50) stops the exponent from growing beyond the 50th position
		const volume = Math.round(impressions * Math.cbrt((Math.min(Math.max(position - 3, 1), 50) + 1) / 2)) + 2
		return volume > 1000 ? Math.round(volume / 100) * 100 : Math.round(volume / 10) * 10
	} else {
		return undefined
	}
}