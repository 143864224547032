import { api } from "@/api"
import dayjs from "dayjs"

export async function getWorkspaceNotionTasks(workspaceId) {
	const res = await api.get(`/api/workspaces/${workspaceId}/notion-tasks`)
	if (res?.data) {
		return res.data.results.map(row => {
			const task = {
				title: row.properties["Agenda Item"]?.formula?.string,
				status: row.properties["Status"]?.status?.name,
				flair: row.properties["Flair"]?.multi_select.map(item=> item.name),
				link: row.properties["Link"]?.url,
				campaign: row.properties["Campaign"]?.rich_text?.at(0) ? row.properties["Campaign"]?.rich_text?.at(0).plain_text : "",
				notes: row.properties["Client notes"]?.rich_text?.at(0) ? row.properties["Client notes"]?.rich_text?.at(0).plain_text : "",
				due: row.properties["Due date"]?.date?.start,
				template_name: row.properties["Template Text"]?.formula?.string || "",
				drive_link: row.properties["Drive Link"]?.formula?.string || undefined,
				client_name: row.properties["Client text"]?.formula?.string || undefined,
			}
			task.expected = task.due ? dayjs(task.due).valueOf() > dayjs().valueOf() : false
			return task
		})
	} else {
		return undefined
	}
}

