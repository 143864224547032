import { api } from "@/api"
import { processPages, scorePages } from "@/utils"

export async function getLibraryPages(libraryId, options) {
	const params = { 
		pn: options.pageNumber || 1,
		ps: options.pageSize || 10,
		so_group: options.sortGroup || undefined,
		so_field: options.sortField || "page_views",
		so_direction: options.sortDirection || "DESC",
	}

	if (options.searchText) {
		params.filter_expression = {
			filter_expression_type: "TEXT_CONTAINS",
			filter: {
				"search_text": options.searchText,
				"search_fields": ["BEST_TITLE", "CANONICAL_PAGE_ID"]
			}
		}
	}

	const res = await api.get(`/api/libraries/${libraryId}/pages`, { 
		params: {
			json: JSON.stringify(params)
		} 
	})

	if (res?.data?.results) {
		const count = res.data?.count || 0
		const processedPages = processPages(res.data.results)
		const pages = scorePages(processedPages)
		console.log(pages)
		return { count, pages }
	} else {
		return
	}
}

