import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { copyItemsToClipboard } from "@/utils";
export default {
  __name: 'TableCopyString',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    format: {
      type: String,
      default: "raw"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_hero_clipboard_document_check = _resolveComponent("hero-clipboard-document-check");
      return _openBlock(), _createElementBlock("a", {
        class: "block my-7 text-sm text-center italic hover:cursor-pointer hover:underline select-none",
        onClick: _cache[0] || (_cache[0] = $event => _unref(copyItemsToClipboard)(__props.items, __props.format))
      }, [_createTextVNode(" Copy all to clipboard (paste into any spreadsheet) "), _createVNode(_component_hero_clipboard_document_check, {
        class: "h-5 w-5 inline text-gray-600 hover:text-gray-900 ml-2"
      })]);
    };
  }
};