import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";

import { computed } from 'vue';
const _hoisted_1 = {
  class: "select-none truncate"
};
export default {
  __name: 'BaseBadge',
  props: {
    size: {
      type: String,
      default: "md"
    },
    color: {
      type: String,
      default: "zinc"
    },
    fill: {
      type: String,
      default: "outline"
    }
  },
  setup(__props) {
    const props = __props;
    const colors = {
      "stone-outline": "border border-stone-500 text-stone-500",
      "stone-solid": "bg-stone-500 text-white",
      "stone-light": "bg-stone-200 text-stone-600",
      "zinc-outline": "border border-zinc-500 text-zinc-500",
      "zinc-solid": "bg-zinc-500 text-white",
      "zinc-light": "bg-zinc-200 text-zinc-600",
      "blue-outline": "border border-blue-500 text-blue-600",
      "blue-solid": "bg-blue-500 text-white",
      "blue-light": "bg-blue-200 text-blue-700",
      "amber-outline": "border border-amber-600 text-amber-700",
      "amber-solid": "bg-amber-600 text-white",
      "amber-light": "bg-orange-200 text-amber-700",
      "yellow-outline": "border border-yellow-500 text-yellow-600",
      "yellow-solid": "bg-yellow-500 text-white",
      "yellow-light": "bg-yellow-200 text-yellow-700"
    };
    const compClass = computed(() => {
      let size;
      const style = colors[`${props.color}-${props.fill}`] || colors["stoneOutline"];
      if (props.size === "xs") {
        size = "rounded-[4px] text-[11px] py-[2px] px-[6px] leading-3";
      } else if (props.size === "sm") {
        size = "rounded-[4px] text-[12px] py-[3px] px-[6px] leading-3";
      } else if (props.size === "md") {
        size = "rounded-[5px] text-[12px] py-[4px] px-[10px] leading-3";
      } else if (props.size === "lg") {
        size = "rounded-[6px] text-[13px] py-[5px] px-[12px] leading-3";
      } else if (props.size === "xl") {
        size = "rounded-[7px] text-[14px] py-[6px] px-[14px] leading-3";
      }
      return `${size} ${style}`;
    });
    return (_ctx, _cache) => {
      const _component_BaseTooltip = _resolveComponent("BaseTooltip");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["inline-block font-normal flex-none group", _unref(compClass)])
      }, [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default")]), _ctx.$slots.tooltip ? (_openBlock(), _createBlock(_component_BaseTooltip, {
        key: 0
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "tooltip")]),
        _: 3
      })) : _createCommentVNode("", true)], 2);
    };
  }
};