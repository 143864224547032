import { postLibrary, postLibraryPublish, getLibraryStatus } from "@/api/library";
import { useVuelidate } from "@vuelidate/core";
import { minLength, maxLength, required, helpers } from "@vuelidate/validators";
import isValidHostname from "is-valid-hostname";
const hasNoSpaces = value => !helpers.req(value) || !(value.trim().indexOf(" ") >= 0);
export const useLibraryFormStore = defineStore("libraryForm", () => {
  const userStore = useUserStore();
  const notificationStore = useNotificationStore();
  let libraryCheckInterval;
  const estimationMultiplier = 1.3;
  const libPreview = ref(null);
  const filteredLibPreview = ref(null);
  const formInit = {
    libraryName: null,
    accountSelect: null,
    webPropSelect: null,
    gscPropertySelect: null,
    pathFilterType: null,
    pathFilterValues: [],
    hostnameFilterType: null,
    hostnameFilterValues: []
  };
  const form = reactive({
    ...formInit
  });
  const rules = {
    libraryName: {
      required: helpers.withMessage("A library name is required.", required),
      minLength: helpers.withMessage("Library name should have at least 3 characters.", minLength(3)),
      maxLength: helpers.withMessage("Library name should be less than 100 characters.", maxLength(100))
    },
    accountSelect: {
      required: helpers.withMessage("A Google Analytics account is required to create a library.", required)
    },
    gscPropertySelect: {},
    webPropSelect: {
      required: helpers.withMessage("A web property is required to create a library.", required)
    },
    hostnameFilterValues: {
      allValidHostnames: helpers.withMessage("All values must be valid hostnames.", helpers.forEach({
        title: {
          isValidHostname
        }
      }))
    },
    pathFilterValues: {
      noneHaveSpaces: helpers.withMessage("Path filters cannot contain spaces.", helpers.forEach({
        title: {
          hasNoSpaces
        }
      }))
    }
  };
  const v$ = useVuelidate(rules, form, {
    $lazy: true
  });
  watch(() => form.webPropSelect, () => {
    resetFilters();
  });
  const librarySizeEstimate = computed(() => {
    let est = libPreview.value?.onboarding_metadata?.page_count_estimation;
    if (!est && est !== 0) return null;
    if (form.pathFilterValues?.length > 0) {
      const pathSum = form.pathFilterValues.reduce((sum, current) => sum + current.count, 0);
      est = form.pathFilterType === "INCLUDE" ? pathSum : est - pathSum;
    } else if (form.hostnameFilterValues?.length > 0) {
      const hostSum = form.hostnameFilterValues.reduce((sum, current) => sum + current.count, 0);
      est = form.hostnameFilterType === "INCLUDE" ? hostSum : est - hostSum;
    }
    return round(est * estimationMultiplier);
  });
  watch([() => form.accountSelect], () => {
    form.webPropSelect = null;
  });
  function resetForm() {
    clearLibraryCheckInterval();
    Object.assign(form, formInit);
    v$.value.$reset();
    libPreview.value = null;
    filteredLibPreview.value = null;
  }
  function resetFilters() {
    clearLibraryCheckInterval();
    form.pathFilterType = null;
    form.hostnameFilterType = null;
    form.pathFilterValues = [];
    form.hostnameFilterValues = [];
    v$.value.$reset();
    libPreview.value = null;
    filteredLibPreview.value = null;
  }
  function getLibraryBody(withFilters = false) {
    const body = {
      name: form.libraryName,
      account_id: form.accountSelect.id,
      ga4_property_id: form.webPropSelect.id
    };
    if (form.gscPropertySelect) {
      body.gsc_siteurl = form.gscPropertySelect.site_url;
    }
    if (form.hostnameFilterType && withFilters) {
      body.hostname_filter_type = form.hostnameFilterType;
      body.hostname_filter_values = form.hostnameFilterValues?.map(item => item.title);
    }
    if (form.pathFilterType && withFilters) {
      body.path_filter_type = form.pathFilterType;
      body.path_filter_values = form.pathFilterValues?.map(item => item.title);
    }
    return body;
  }
  function setLibraryCheckInterval(workspaceId, libraryId, withFilters = false) {
    clearLibraryCheckInterval();
    libraryCheckInterval = setInterval(libraryCheck, 2000, workspaceId, libraryId, withFilters);
  }
  function clearLibraryCheckInterval() {
    clearInterval(libraryCheckInterval);
  }
  async function createLibrary(workspaceId, withFilters = false) {
    const isValid = await v$.value.$validate();
    if (!isValid) return;
    const res = await postLibrary(workspaceId, getLibraryBody(withFilters));
    if (withFilters) {
      filteredLibPreview.value = null;
    } else {
      libPreview.value = null;
    }
    if (res) {
      setLibraryCheckInterval(workspaceId, res.id, withFilters);
      return true;
    } else {
      return false;
    }
  }
  async function libraryCheck(workspaceId, libraryId, withFilters) {
    const res = await getLibraryStatus(workspaceId, libraryId);
    if (res && res.onboarding_status?.stage === "METADATA_PROCESS" && res.onboarding_status?.status === "COMPLETE") {
      if (withFilters) {
        filteredLibPreview.value = res;
      } else {
        libPreview.value = res;
      }
      clearLibraryCheckInterval();
    } else if (!res) {
      clearLibraryCheckInterval();
    }
  }
  async function publishLibrary(workspaceId, libraryId) {
    const res = await postLibraryPublish(workspaceId, libraryId);
    if (res) {
      userStore.klaviyoCall("Created Library", {
        library_id: res.id
      });
      await userStore.getWorkspaceLibraries();
      resetForm();
      return true;
    } else {
      notificationStore.setError("Library could not be created, try refreshing the page and starting over.");
      return false;
    }
  }
  return {
    ...toRefs(form),
    v$,
    resetForm,
    publishLibrary,
    createLibrary,
    libPreview,
    filteredLibPreview,
    librarySizeEstimate
  };
});