export class TableConfig {
	constructor(options) {
		const sortString = options.sortGroup ? `${options.sortField}_${options.sortGroup}` : options.sortField

		this.syncing = false
		this.by = options.by || sortString
		this.sortField = options.sortField || "page_views"
		this.sortGroup = options.sortGroup || undefined
		this.dir = options.dir || "DESC"
		this.key = options.key || "id"
		this.pageNumber = options.pageNumber || 1
		this.pageSize = options.pageSize || 100
		this.totalItems = undefined
		this.searchText = options.searchText
		this.searchField = options.searchField || "page_text"
		this.checkboxes = options.checkboxes || false
		this.checked = {}
		this.allItems = []
	}

	setSort(sortField, sortGroup, reverse = false){
		const sortString = sortGroup ? `${sortField}_${sortGroup}` : sortField

		if (sortString === this.by) {
			if (this.dir === "ASC") this.dir = "DESC"
			else if (this.dir === "DESC") this.dir = "ASC"
		} else {
			this.by = sortString
			this.sortField = sortField
			this.sortGroup = sortGroup
			this.dir = reverse ? "ASC" : "DESC"
		}

		this.pageNumber = 1
	}

	setPage(pn) {
		this.pageNumber = pn
	}

	setSearch(searchText) {
		this.searchText = searchText
	}

	get() {
		return {
			pageSize: this.pageSize, 
			pageNumber: this.pageNumber, 
			searchText: removeProtocolInString(this.searchText),
			sortGroup: this.sortGroup,
			sortDirection: this.dir,
			sortField: this.sortField,
		}
	}

	getCheckedItems() {
		const filteredItems = Object.keys(this.checked).map(key => this.checked[key])
		return sortTableItems(filteredItems, this.by, this.dir)
	}

	toggleCheck(item) {
		if (this.checked[item[this.key]]) {
			delete this.checked[item[this.key]]
		} else {
			this.checked[item[this.key]] = item
		}
	}

	toggleAllChecks(items) {
		if (this.allChecked(items)) {
			items.forEach(item => {
				delete this.checked[item[this.key]]
			})
		} else {
			items.forEach(item => {
				this.checked[item[this.key]] = item
			})
		}
	}

	clearAllChecks() {
		this.checked = {}
	}

	isChecked(item) {
		if (!item) return
			
		return !!this.checked[item[this.key]]
	}

	allChecked(items) {
		if (!items?.length > 0) return
			
		let anyUnchecked = false
		items.every(item => {
			if (!this.checked[item[this.key]]) {
				anyUnchecked = true
				return false
			} else {
				return true
			}
		})
		return !anyUnchecked
	}

	localFetch(items) {
		if (!items || items?.length == 0) return []
		let itemsToProcess = items

		if (this.searchText && this.searchField && this.searchText != "") {
			itemsToProcess = filterTableItems(itemsToProcess, this.searchField, this.searchText)
		}

		if (this.by && this.by !== "") {
			itemsToProcess = sortTableItems(itemsToProcess, this.by, this.dir)
		}

		this.allItems = itemsToProcess
		this.totalItems = itemsToProcess?.length
		const sliceStart = (this.pageNumber - 1) * this.pageSize
		return itemsToProcess.slice(sliceStart, sliceStart + this.pageSize)
	}

}

function sortTableItems(items, sortBy, sortDir) {
	const properties = sortBy.split(".")

	return items.toSorted((a,b)=>{
		properties.forEach(prop => {
			a = a !== undefined ? a[prop] : undefined
			b = b !== undefined ? b[prop] : undefined
		})

		if (typeof a == "string" && typeof b == "string") {
			if (sortDir == "DESC") {
				return a.toLowerCase() > b.toLowerCase() ? 1 : -1
			} else {
				return a.toLowerCase() > b.toLowerCase() ? -1 : 1
			}
		} else if (a === undefined || b === undefined) {
			if (a === undefined) {
				return sortDir === "DESC" ? 1 : -1
			} else if (b === undefined) {
				return sortDir === "DESC" ? -1 : 1
			}
		} else {
			if (sortDir == "DESC") {
				return a > b ? -1 : 1
			} else {
				return a > b ? 1 : -1
			}
		}
	})
}

function filterTableItems(items, searchField, searchText) {
	const searchString = removeProtocolInString(searchText)
	const searchProps = searchField.split(".")

	return items.filter(item =>{
		searchProps.forEach(prop => {
			item = item[prop] !== undefined ? item[prop] : undefined
		})

		if (item && item.length > 0) {
			return item.toLowerCase().indexOf(searchString.toLowerCase()) >= 0
		} else {
			return false
		}
	})
}

function removeProtocolInString(str) {
	if (!str) return

	if (str.indexOf("https://") === 0 ) {
		return str.substring(8)
	} else if (str.indexOf("http://") === 0 ) {
		return str.substring(7)
	} else {
		return str
	}
}